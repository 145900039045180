import Cookies from 'js-cookie';

const $devisIndicator = document.getElementById('js-devis-indicator');
const $linkDevis = document.querySelector('.link.devis');
let codics;
let codicsLength = 0;

const getDevisCodics = () => {
    codics = Cookies.get('DARTY_COM_DEVIS');
    return codics;
};

const getDevisPageUrl = () => {
    const codicsCookie = getDevisCodics();
    let url = '/nav/extra/devis';
    if (codicsCookie) {
        const codicsArray = codicsCookie.split(':');
        const codicsParam = codicsArray.join(':');
        url += `?codics=${codicsParam}`;
    }
    return url;
};

const numberOfProductsInDevis = (codicsCookie) => {
    if (codicsCookie !== undefined) {
        codicsLength = codicsCookie.split(':').length;
    }
    return codicsLength;
};

export const updateDevisIndicator = () => {
    getDevisCodics();
    const numberOfProducts = numberOfProductsInDevis(codics);

    if (!$devisIndicator) return;

    if (numberOfProducts > 0) {
        $devisIndicator.classList.add('show');
        $devisIndicator.textContent = numberOfProducts;

        if (numberOfProducts > 9) {
            $devisIndicator.classList.add('indicator-full');
        }

        // maj de l'url du lien devis
        $linkDevis.href = getDevisPageUrl();
    }
};

export default function devisIndicatorInit() {
    updateDevisIndicator();
}
